import sec1BG from '../asset/introduce_bg1024.png'
import whiteBG from '../asset/home-white-bg.png'
import joinUs from '../asset/joinUs-container.png'
import Footer from './footer'
import './home.css'

const FisrtSection = () => {
    return (
        <div>
            <div className="home-section" >
                <img src={sec1BG} />
                <div className='text-container'>
                    <div>
                        <h1> Medical and Heath Tech Innovation </h1>
                    </div>
                    <div>
                        <p>Vertical Integration and State of the Art Internal Systems/Solutions
                            Strong Customer Relations Built on Trust
                            Solid Financial Position
                            Employee and Community Respect
                            Technology
                            Operational Excellence
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

const SecondSection = () => {
    return (<div>
        <div className="home-section" >
            <img src={whiteBG} />
            <div className='partner-container'>
                <div>
                    <h1>OUR PARTNERS</h1>
                </div>
                <div>
                    <p>
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                        ------------------------  ------------------------
                    </p>
                </div>
            </div>
            <div className="joinUs-container">
                <img src={joinUs} />
            </div>
        </div>

    </div>)
}
const HomePage = props => {
    return (
        <div className='home-page'>
            <div className="home-container">
                <FisrtSection />
                <SecondSection />
                <Footer />
            </div>
        </div>


    )

}

export default HomePage