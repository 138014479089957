import footerBG from '../asset/footer_bg.png'

import './footer.css'
const Footer = () => {
    return (
        <div>
            <div className="footer-sec" >
                <img src={footerBG} />
                <div className='footer-container'>
                    <div className='footer-right'>dsfsdffsdfdsf
                        dfsdfdf</div>
                    <div className='footer-right'>dsfsdffsdfdsf
                        1234223</div>
                </div>
            </div>
        </div>

    )
}
export default Footer
