
import React, { useState, useEffect } from 'react';
import verveLogo from './asset/company_logo_header.png'
import HomePage from './pages/home'
import ServicesPage from './pages/services'
import AboutUsPage from './pages/aboutUs'
import ContactUsPage from './pages/contactUs'
import { BrowserRouter as Router, useRoutes, Link } from "react-router-dom";
import './App.css';

const GetPage = () => {
  let routes = useRoutes([
    { path: "/", element: < HomePage /> },
    { path: "/services", element: < ServicesPage /> },
    { path: "/about-us", element: < AboutUsPage /> },
    { path: "/contact-us", element: < ContactUsPage /> }
    // ...
  ]);
  return routes
}
const App = () => {
  return (
    <div>
      <nav className="navbar background">
        <div className="logotNav">
          <img src={verveLogo} className="headerLogo" alt="logo" />
        </div>
        <ul className="nav-list">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/contact-us">Contact Us</Link></li>
        </ul>
      </nav>
      <GetPage />
      {/* <footer className="footer">
        <p className="text-footer">
          Copyright ©-All rights are reserved
        </p>
      </footer> */}
    </div >
  )
}

export default App